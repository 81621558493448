import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import MyProfileSidebar from "./my-profile/myProfileSidebar";
import UtilsService from "../container/utils/utils";
import $ from "jquery";
import constant from "../constants/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from 'lodash';
import { countryApiAction } from '../commonAction/countryApiAction';
import UtilService from '../container/utils/utils';
import config from '../constants/config';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            isNonMember: false,
            slideBar:true
        }
        this.hideSideBar = this.hideSideBar.bind(this);
        this.openAccordion = this.openAccordion.bind(this);
    }

    hideSideBar = () => {
        UtilsService.hideSideBar();
    }

    openAccordion(e,usr_evt,slider) {
        if(usr_evt === "usr_evt" && !slider) {
            this.setState({slideBar:true})
        }
        let parent;
        if (e.target.parentNode.classList[0] === "arrow") {
            parent = e.target.parentNode.parentNode.parentNode;
        } else {
            parent = e.target.parentNode;
        }
        let callName = parent.classList
        var result = Object.keys(callName).map(function (key) {
            return callName[key];
        });

        if (result[1] === 'active' || result[3] === 'active') {
            parent.classList.remove('active')
        } else {
            let parentClass = document.querySelector(".accordion-wrapper")
            $(parentClass).find('.open-panal').removeClass('active')
            if(parentClass,parent.classList[2] === "usr_slide") {
                parent.parentNode.classList.add('active')
            } else{
                parent.classList.add('active')
            }
        }
    }

    tooltipHandler(value) {
        this.setState({
            showTooltip: value
        });
    }

    hadleSlideClose = (e) =>{
        this.setState({slideBar:!this.state.slideBar})
        let parentClass = document.querySelector(".accordion-wrapper")
        $(parentClass).find('.open-panal').removeClass('active')
    }

    componentDidMount = async () => {
        let clubList = JSON.parse(localStorage.getItem('clubs'));
        this.setState({
            isNonMember: _.isEmpty(clubList)
        })
        let countryApiData = await UtilService.getCountryList();
        if (countryApiData === null) {
            await this.props.dispatch(countryApiAction())
        }
    }

    render() {
        let roleId = UtilsService.getUserSingleDataFromLoaclStorage('roleId');
        let clubNameFromStorage = UtilsService.getFirstIdxClubsData('clubName')
        let history = this.props.history
    
if ($(window).width() < 768) { 
        $('.nitOverLay').click(function(){
            $('.sidebar').addClass('nitActive');
            $(this).addClass('nitActive');
        })
        $('header #nitbars').click(function(){
            $('.sidebar').removeClass('nitActive');
            $(this).removeClass('nitActive');
        })
      
        $('.tab-menu-bar').click(function(e){
           e.preventDefault()
        })

        $('header').click(function(){
            $('.sidebar').removeClass('nitActive');
            $('body').attr('style','overflow-x:hidden');
            $('body').css('overflow-x','hidden');
            $('body').add('style');
          });
    }
    
        return (
            <aside className={`sidebar d-md-flex flex-column justify-content-between accordion-wrapper overflow-auto navbar-primary ${this.state.slideBar ? '' : 'collapsed'} `}>
                <nav>
                <a  style={{ cursor: 'pointer' }} className="btn-expand-collapse"><span onClick={this.hadleSlideClose}>{this.state.slideBar ? <span>
                    <i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="20"  width="20" fill="white"><path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"/></svg></i>
                 <b>Collapse sidebar</b></span>:
                 <i className="right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="20"  width="20" fill="white"><path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z"/></svg></i>}</span></a>

                    <ul className="sidebar-panal m-0 p-0 ">
                        <li className="open-panel home">
                            <NavLink to="/">
                            <i className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 556" height="20" width="20" fill="white"><path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg></i> <b className='font-16'>Home</b>
                            </NavLink>
                        </li>
                        <li
                            className={` open-panal ${history.location.pathname === '/club-management' ||
                                history.location.pathname === '/clubChannelList' ||
                                history.location.pathname === '/add-club' ||
                                history.location.pathname === '/user-management' ||
                                history.location.pathname === '/player' ||
                                history.location.pathname === '/club-table' ||
                                history.location.pathname === '/club-rating' ||
                                history.location.pathname === '/table-schedule' ||
                                history.location.pathname === '/addPlayer-tournament' ||
                                history.location.pathname === '/food-beverage' ||
                                history.location.pathname === '/add-table' ||
                                history.location.pathname === '/club-admin' ||
                                history.location.pathname === '/match' ||
                                history.location.pathname === '/tournament' ||
                                history.location.pathname === '/add-player' ||
                                history.location.pathname === '/create-match' ||
                                history.location.pathname === '/match' ||
                                history.location.pathname === '/pabsa-admin' ||
                                history.location.pathname === '/manage-mobile-apps' ||
                                history.location.pathname === '/payments-members' ||
                                history.location.pathname === '/payments-members-Cs' ||
                                history.location.pathname === '/payments-members-club' ||
                                history.location.pathname === '/manageProduct' ||
                                history.location.pathname === '/productPrice' ||
                                history.location.pathname === '/news' ||
                                history.location.pathname === '/payments-members-platform' ||
                                history.location.pathname === '/payments-clubs' ||
                                history.location.pathname === '/all-transactions' ||
                                history.location.pathname === '/my-transactions' ||
                                history.location.pathname === '/sponsored-transactions' ||
                                history.location.pathname === '/statistics' ||
                                history.location.pathname === '/reports' ||
                                history.location.pathname === '/payment-report' ||
                                history.location.pathname === '/manage-ranking' ||
                                history.location.pathname === '/schedule-table' ||
                                history.location.pathname === '/create-tournament' ? "active" : ''}`}
                        >
                            {(roleId === 3 || roleId === 4) && (
                                <div
                                    onMouseOver={this.tooltipHandler.bind(this, true)}
                                    onMouseOut={this.tooltipHandler.bind(this, false)}
                                    className="position-relative"
                                >
                                    <span className="user-club-name d-md-inline-block">
                                    <i className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="20"  width="18" fill="white"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c39.77 0 72 32.24 72 72S295.8 272 256 272c-39.76 0-72-32.24-72-72S216.2 128 256 128zM256 448c-52.93 0-100.9-21.53-135.7-56.29C136.5 349.9 176.5 320 224 320h64c47.54 0 87.54 29.88 103.7 71.71C356.9 426.5 308.9 448 256 448z"/></svg></i> <b>{clubNameFromStorage}</b>
                                    </span>
                                    <div className={`${this.state.showTooltip ? 'user-club-tooltip' : 'd-none'}`}>
                                        {clubNameFromStorage}
                                    </div>
                                </div>
                            )}
                            <span className="position-relative mt-0 open-accordion usr_slide " onClick={(e)=>this.openAccordion(e,"usr_evt",this.state.slideBar)}>
                            <i className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" height="20"  width="20" fill="white"><path d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/></svg></i>
                            {/*<svg className="mr-2 pointer-event" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g opacity=".95">
                                        <path d="M24 0H0v24h24z" data-name="Path 190" fill="transparent" />
                                        <path d="M9 12a4 4 0 114-4 4 4 0 01-4 4zm9-2V7h2v3h3v2h-3v3h-2v-3h-3v-2zm-9 4c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4z" data-name="Path 191" fill="#ffffff" />
                                    </g>
                            </svg>*/}
                               {
                                    roleId === 1 ? constant.PABSA_SUPER_ADMIN :
                                        roleId === 2 ? constant.PABSA_ADMIN :
                                            roleId === 3 ? constant.CLUB_SUPER_ADMIN :
                                                roleId === 4 ? constant.CLUB_ADMIN :
                                                    roleId === 5 ? constant.PLAYER : ''}
                                <b>   <svg className="arrow pointer-event" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_arrow_up" width="24" height="24" viewBox="0 0 24 24" fill="white">
                                    <path d="M0 24h24V0H0z" data-name="Path 157" fill="transparent" />
                                    <path d="M0 1.41L4.58 6 0 10.59 1.41 12l6-6-6-6z" data-name="Path 156" transform="rotate(90 4.557 13.147)"  />
                                </svg></b>
                            </span>

                            <ul className="m-0 p-0">
                                {roleId === 1 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/club-management" onClick={this.hideSideBar}>
                                                Manage Club
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/pabsa-admin" onClick={this.hideSideBar}>
                                                Manage CS Admin
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/manage-mobile-apps" onClick={this.hideSideBar}>
                                                Manage Mobile Apps
                                            </NavLink>
                                        </li>

                                    </React.Fragment>
                                )}
                                {roleId === 3 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/club-table" onClick={this.hideSideBar}>
                                                Manage Tables
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/club-rating" onClick={this.hideSideBar}>
                                                Manage Rating
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                                {(roleId === 3 || (roleId === 5 && !this.state.isNonMember)) && (
                                    <React.Fragment>
                                        {/* <li>
                                            <NavLink to="/table-schedule" onClick={this.hideSideBar}>
                                                Manage Table Schedules
                                            </NavLink>
                                        </li> */}
                                    </React.Fragment>
                                )}
                                {(roleId === 3) && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/manage-ranking" onClick={this.hideSideBar}>
                                                Manage Ranking
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}

                                {roleId === 3 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/food-beverage" onClick={this.hideSideBar}>
                                                Manage Food & Beverage
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                                {(roleId === 3) && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/schedule-table" onClick={this.hideSideBar}>
                                                Manage Table Schedule
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                                {roleId === 3 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/club-admin" onClick={this.hideSideBar}>
                                                Manage Club Admin
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                                {(roleId === 3 || roleId === 1 || roleId === constant.PABSA_ADMIN_VALUE || roleId === constant.CLUB_ADMIN_VALUE) && (
                                    <React.Fragment>
                                        <li className="open-panal">
                                            <NavLink to="/player" onClick={this.hideSideBar}>
                                                Manage Member
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/match" onClick={this.hideSideBar}>
                                                Manage Match
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/tournament" onClick={this.hideSideBar}>
                                                Manage Tournament
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/news" onClick={this.hideSideBar}>
                                                Manage News
                                            </NavLink>
                                        </li>
                                        {roleId === constant.PABSA_ADMIN_VALUE || roleId === constant.CLUB_ADMIN_VALUE ?
                                            null
                                            :
                                            <li>
                                                <NavLink to="/clubChannelList" onClick={this.hideSideBar}>
                                                    Club Management
                                                </NavLink>
                                            </li>
                                        }
                                    </React.Fragment>
                                )}
                                {roleId === 3 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/payments-members" onClick={this.hideSideBar}>
                                                Payments - Members
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/sponsored-transactions" onClick={this.hideSideBar}>
                                                Sponsored Transactions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/all-transactions" onClick={this.hideSideBar}>
                                                Member Transactions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/my-transactions" onClick={this.hideSideBar}>
                                                My Transactions
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                                {(roleId === 1 || roleId === 3) && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/manageProduct" onClick={this.hideSideBar}>
                                                Manage Product
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}

                                {roleId === 1 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/payments-members-club" onClick={this.hideSideBar}>
                                                Payments - members (for clubs)
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/payments-clubs" onClick={this.hideSideBar}>
                                                Payments - Clubs
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/payments-members-platform" onClick={this.hideSideBar}>
                                                Payments - Members (for platform)
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to="/all-transactions" onClick={this.hideSideBar}>
                                                All Transactions
                                            </NavLink>
                                        </li>


                                    </React.Fragment>
                                )}

                                {roleId === 5 && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/match" onClick={this.hideSideBar}>
                                                Manage Match
                                            </NavLink>
                                        </li>
                                        {!this.state.isNonMember && (
                                            <React.Fragment>
                                                <li>
                                                    <NavLink to="/schedule-table" onClick={this.hideSideBar}>
                                                        Manage Table Schedule
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/addPlayer-tournament" onClick={this.hideSideBar}>
                                                        Tournaments
                                                    </NavLink>
                                                </li>
                                            </React.Fragment>
                                        )}
                                        <li>
                                            <NavLink to="/all-transactions" onClick={this.hideSideBar}>
                                                My Transactions
                                            </NavLink>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </li>
                        {roleId === 5 && (
                            <React.Fragment>
                                <li className='home news'>
                                    <NavLink to="/news" onClick={this.hideSideBar}>
                                        <i className="fa fa-newspaper-o mr-6"></i>
                                        <b className='font-16'>News</b>
                                    </NavLink>
                                </li>
                                <li className='home news'>
                                    <NavLink to={{
                                    pathname:'/statistics',
                                    state: {className:'memberStats'}  
                                    }} onClick={this.hideSideBar}>
                                        <i className="fa fa-bar-chart mr-6"></i>
                                        <b className='font-16'>Statistics</b>
                                    </NavLink>
                                </li>
                            </React.Fragment>
                        )}
                        {(roleId === 3 || roleId === 1) &&
                            <li className="open-panal">
                                <span className="position-relative open-accordion usr_slide" onClick={(e) => this.openAccordion(e, "usr_evt", this.state.slideBar)}>
                                    <i className="mr-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="20" width="20" fill="white"><path d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z" /></svg></i>
                                    Reports
                                    <b>  <svg className="arrow pointer-event" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_arrow_up" width="24" height="24" viewBox="0 0 24 24" fill="white">
                                        <path d="M0 24h24V0H0z" data-name="Path 157" fill="transparent" />
                                        <path d="M0 1.41L4.58 6 0 10.59 1.41 12l6-6-6-6z" data-name="Path 156" transform="rotate(90 4.557 13.147)" />
                                    </svg></b>
                                </span>
                                <ul className="m-0 p-0">
                                    <React.Fragment>
                                        {(roleId === 3 || roleId === 1) && <li>
                                            <NavLink to="/reports" onClick={this.hideSideBar}>
                                                Member Login/Logout Report
                                            </NavLink>
                                        </li>}
                                        {roleId === 1 && <li>
                                            <NavLink to="/payment-report" onClick={this.hideSideBar}>
                                                Payment Report
                                            </NavLink>
                                        </li>}
                                    </React.Fragment>
                                </ul>
                            </li>
                        }
                        {roleId === 1 && (

                            <li className="open-panal d-none">
                                <div className="sliding-btn d-flex align-items-center">
                                    <label className="mb-0 mr-3 text-white ml-3">
                                        Payment - Off
                                        {/* {this.props.checked ? 'Active' : 'Suspend'} */}
                                    </label>

                                    <label className="switch">
                                        <input type="checkbox"
                                        // onChange={this.props.changeStatus}
                                        // checked={this.props.checked}
                                        />
                                        <span className="slider-switch"></span>
                                    </label>
                                </div>
                            </li>
                        )
                        }



                        {/* <li className="open-panal">
                           <span className="position-relative">
                                <svg className="mr-2 pointer-event" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="prefix__ic_admin" fillOpacity="0.95">
                                        <path id="prefix__Path_190" d="M24 0H0v24h24z" data-name="Path 190" fill="transparent"/>
                                        <path id="prefix__Path_191" d="M9 12a4 4 0 114-4 4 4 0 01-4 4zm9-2V7h2v3h3v2h-3v3h-2v-3h-3v-2zm-9 4c2.67 0 8 1.34 8 4v2H1v-2c0-2.66 5.33-4 8-4z" data-name="Path 191" fill="#fff"/>
                                    </g>
                                </svg>
                                Manage Club Admin
                            </span>
                       </li> */}

                    </ul>
                    <div className={`d-lg-none d-md-none open-panal position-relative ${history.location.pathname === '/profile/my-profile' || history.location.pathname === '/profile/change-password' ? "active" : ''}`} >
                        <div className="sidebar-opener" onClick={this.openAccordion}>
                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" id="prefix__ic_arrow_up" width="24" height="24" viewBox="0 0 24 24" fill="white">
                                <path d="M0 24h24V0H0z" data-name="Path 157" fill="transparent" />
                                <path d="M0 1.41L4.58 6 0 10.59 1.41 12l6-6-6-6z" data-name="Path 156" transform="rotate(90 4.557 13.147)" />
                            </svg>
                        </div>

                        <MyProfileSidebar />
                    </div>
                </nav>
                <footer className="footer d-none d-md-block text-center">
                    {/* <em className="powered-by d-block mb-2">Powered by</em>
                    <span>Cloud Snooker</span> */}
                    <p className="m-0">© {config.CURRENT_YEAR} cloudsnooker.com. All rights reserved.</p>
                </footer>
            </aside>
        );
    }
}
const mapStateToProps = state => {
    return {
        header: state.signoutReducer
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
});
// export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SideBar);
