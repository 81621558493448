import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { MainHeader } from '../mainHeader';
import ClubAppLogo from "../../assets/images/scoring-logo.png";
import group_11845 from "../../assets/images/group-11845.png";
import group_11845_hover from "../../assets/images/android-white.png";
import appleLogo from "../../assets/images/apple-logo-black.png";
import appleWhite from "../../assets/images/apple-white.png";
import ScoringAppLogo from "../../assets/images/scoring-app-logo.png";
import StreamingAppLogo from "../../assets/images/streamingLogo.png";
import ReactNativeIcon from "../../assets/images/react-native-icon.png";
import ReactNativeWhite from "../../assets/images/react-native-white.png";
import { toast } from 'react-toastify';
import API from "../utils/api";
import config from "../../constants/config";
import { Footer } from '../footer';
import $ from "jquery";
import { APP_TYPES, PLATFORM_TYPES } from '../../constants/constant';

class Download extends Component {
    _isMounted = false; // Prevent state update on unmounted component

    state = {
        hoveredAndroid: null,
        hoveredIOS: null,
        hoveredReactNativeAndroid: null,
        apps: [],
        downloadAppURL: '',
        downloadApp: '',
    };

    handleMouseEnter = (platform, id) => {
        this.setState({ [platform]: id });
    };

    handleMouseLeave = (platform) => {
        this.setState({ [platform]: null });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getAppDetails();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAppDetails = async () => {
        this.setState({ isLoadingData: true });
        try {
            const response = await API.getRequest(`${config.BASE_URL}/mobile-apps/all-active`);
            if (this._isMounted) {
                this.setState({ apps: response || [], isLoadingData: false });
            }
        } catch (error) {
            console.error(error);
            if (this._isMounted) {
                this.setState({ isLoadingData: false });
                toast.error("Something went wrong.");
            }
        }
    };

    getLogo = (appName) => {
        switch (appName) {
            case APP_TYPES.CLUB:
                return ClubAppLogo;
            case APP_TYPES.SCORING:
                return ScoringAppLogo;
            case APP_TYPES.STREAMING:
                return StreamingAppLogo;
            default:
                return ScoringAppLogo; // Default fallback logo
        }
    };

    getAppDownloadBtns = (app) => {
        const downloadAppList = app.uploads || [];

        const platforms = [
            {
                type: PLATFORM_TYPES.ANDROID,
                stateKey: 'hoveredAndroid',
                hoveredIcon: group_11845_hover,
                defaultIcon: group_11845,
                label: 'Android'
            },
            {
                type: PLATFORM_TYPES.IOS,
                stateKey: 'hoveredIOS',
                hoveredIcon: appleWhite,
                defaultIcon: appleLogo,
                label: 'iOS'
            },
            {
                type: PLATFORM_TYPES.RN_ANDROID,
                stateKey: 'hoveredReactNativeAndroid',
                hoveredIcon: ReactNativeWhite,
                defaultIcon: ReactNativeIcon,
                label: 'React Native Android'
            }
        ];

        const handleHover = (stateKey, id) => {
            this.setState({ [stateKey]: id });
        };

        const handleDownload = (downloadLink, appType) => {
            if (!downloadLink) return;

            if (appType === PLATFORM_TYPES.RN_ANDROID) {
                this.setState(
                    { downloadApp: 'React Native Android App', downloadAppURL: downloadLink },
                    () => $('#downloadAppModal').modal('show')
                );
            } else {
                const anchor = document.createElement("a");
                anchor.href = downloadLink;
                anchor.setAttribute("download", "");
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

            }


        };

        return (
            <>
                <div className="d-flex justify-content-between">
                    {platforms.slice(0, 2).map(({ type, stateKey, hoveredIcon, defaultIcon, label }) => {
                        const appData = downloadAppList.find(upload => upload.type === type);
                        const isDisabled = !appData;

                        return (
                            <div
                                key={type}
                                className={`download-button ${isDisabled ? 'disabled' : ''}`}
                                onMouseEnter={() => !isDisabled && handleHover(stateKey, appData?.id)}
                                onMouseLeave={() => !isDisabled && handleHover(stateKey, null)}
                                onClick={() => !isDisabled && handleDownload((type === "IOS" ? `itms-services://?action=download-manifest&url=${appData?.url}` : appData?.url), appData?.type)}
                            >
                                <img
                                    src={!isDisabled && this.state[stateKey] === appData?.id ? hoveredIcon : defaultIcon}
                                    className="icon"
                                    alt={`${label} Logo`}
                                />
                                <span className="button-text">{label}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="d-flex justify-content-center mt-3">
                    {(() => {
                        const reactNative = platforms[2];
                        const appData = downloadAppList.find(upload => upload.type === reactNative.type);
                        const isDisabled = !appData;

                        return (
                            <div
                                key={reactNative.type}
                                className={`download-button react-native ${isDisabled ? 'disabled' : ''}`}
                                onMouseEnter={() => !isDisabled && handleHover(reactNative.stateKey, appData?.id)}
                                onMouseLeave={() => !isDisabled && handleHover(reactNative.stateKey, null)}
                                onClick={() => !isDisabled && handleDownload(appData?.url, appData?.type)}
                            >
                                <img
                                    src={!isDisabled && this.state[reactNative.stateKey] === appData?.id ? reactNative.hoveredIcon : reactNative.defaultIcon}
                                    className="icon"
                                    alt={`${reactNative.label} Logo`}
                                />
                                <span className="button-text">{reactNative.label}</span>
                            </div>
                        );
                    })()}
                </div>
            </>
        );
    };

    render() {
        const { apps, downloadApp, downloadAppURL } = this.state;

        return (
            <>
                <div className='download-component'>
                    <MainHeader />
                    <div className="download-wrapper">
                        <div className="download-banner">
                            <span className="download-title">Download Mobile App</span>
                            <div className='d-flex justify-content-between mobile-view'>
                                {apps?.map((app) => (
                                    <div className="app-card" key={app.id}>
                                        <div className="app-card-content">
                                            <div className="app-logo-container">
                                                <img
                                                    src={app.logo || this.getLogo(app.appName)}
                                                    alt="App Logo"
                                                    className="app-logo"
                                                />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <span className="app-title">{app.appName}</span>
                                                <span className="app-description">{app.description}</span>
                                            </div>
                                            {this.getAppDownloadBtns(app)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <Footer />

                    <div className="modal fade" id="downloadAppModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="d-table w-100">
                            <div className="d-table-cell">
                                <div className="modal-dialog  modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body p-0 border-0 mb-3">
                                            <p className="text-center">
                                                Please uninstall the existing scoring app before installing the {downloadApp}.
                                            </p>
                                        </div>
                                        <div className="modal-footer p-0 border-0 justify-content-center">
                                            <button
                                                className="btn red-btn"
                                                onClick={() => {
                                                    window.location.href = downloadAppURL;
                                                    $("#downloadAppModal").modal("hide");
                                                    this.setState({ downloadApp: "", downloadAppURL: "" });
                                                }}
                                            >
                                                OK
                                            </button>
                                            <button
                                                className="btn cancel-btn"
                                                onClick={() => {
                                                    $("#downloadAppModal").modal("hide");
                                                    this.setState({ downloadApp: "", downloadAppURL: "" });
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    login: state.loginReducer,
});

export default compose(withRouter, connect(mapStateToProps))(Download);