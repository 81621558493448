import React from 'react';
import config from '../constants/config';

export const Footer = () => {
    return (
        <footer className="home-page-footer">
            <div className="container">
                <div className="row flex-column align-items-center">

                    <ul className="home-page-footer-nav d-flex mb-4 p-0 pb-1">
                        <li>
                            <a href="/termsConditions" target="_blank">Terms &amp; Conditions</a>
                        </li>
                        <li>
                            <a href="/privacyPolicy" target="_blank">Privacy Policy</a>
                        </li>
                    </ul>
                    <div className="social d-flex mb-4">
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <defs>
                                    <clipPath id="zeqnxi55oa">
                                        <path fill="#02386e" d="M21.154-177.344h-1.505c-1.181 0-1.41.561-1.41 1.384v1.816h2.817l-.367 2.845h-2.45v7.3H15.3v-7.3h-2.454v-2.845H15.3v-2.1A3.427 3.427 0 0 1 18.959-180a20.073 20.073 0 0 1 2.195.112zM17-188a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16z" clipRule="evenodd" transform="translate(-1 188)" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#zeqnxi55oa)" opacity="0.5">
                                    <path fill="#fff" d="M-4-151h42v-42H-4z" transform="translate(-.999 188)" />
                                </g>
                            </svg>
                        </a>
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <defs>
                                    <clipPath id="6l1pms6pga">
                                        <path fill="#fff" d="M75.362-175.263c.006.141.01.282.01.425a9.276 9.276 0 0 1-9.34 9.339A9.294 9.294 0 0 1 61-166.973a6.74 6.74 0 0 0 .783.045 6.585 6.585 0 0 0 4.077-1.4 3.285 3.285 0 0 1-3.067-2.279 3.325 3.325 0 0 0 .618.058 3.3 3.3 0 0 0 .865-.115 3.283 3.283 0 0 1-2.633-3.218v-.042a3.267 3.267 0 0 0 1.487.411 3.282 3.282 0 0 1-1.461-2.732 3.265 3.265 0 0 1 .445-1.651 9.32 9.32 0 0 0 6.766 3.43 3.294 3.294 0 0 1-.085-.748 3.283 3.283 0 0 1 3.282-3.283 3.275 3.275 0 0 1 2.4 1.037 6.569 6.569 0 0 0 2.085-.8 3.3 3.3 0 0 1-1.443 1.816 6.59 6.59 0 0 0 1.881-.518 6.683 6.683 0 0 1-1.638 1.7M69-188a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16" clipRule="evenodd" transform="translate(-53 188)" />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#6l1pms6pga)" opacity="0.5">
                                    <path fill="#fff" d="M48-151h42v-42H48z" transform="translate(-53 188)" />
                                </g>
                            </svg>
                        </a>
                    </div>
                    <p className="mb-2 pb-1">Pan American Billiards &amp; Snooker Association. Developed by: Impressico Business Solutions</p>
                    <p className="copy-right mb-0">© {config.CURRENT_YEAR} cloudsnooker.com. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
