const constant = {
  isReferee:"isReferee",
  CURR_USER:"curr_user",
  USER_ID: "user_id",
  PASSWORD: "password",
  IS_PASSWORD_REMEMBER: "password_remember",
  WEAK_PASSWORD: "Weak",
  STRONG_PASSWORD: "Strong",
  MEDIUM_PASSWORD: "Medium",
  CURRENT_USER: "userDetails",
  AUTH_TOKEN: "authToken",
  TOKEN_EXPIRATION_TIME: "tokenExpirationTime",
  STRONG_PASSWORD_ERROR: "Password policy does not match",
  PASSWORD_NOT_MATCH: "Both password are not match",
  PASSWORD_FIELD_REQUIRED: "Password is Required",
  RE_PASSWORD_FIELD_REQUIRED: "Re- Password is Required",
  NAME_REQUIRED: "Name is Required",
  EMAIL_REQUIRED: "Email is Required",
  CURRENCY_REQUIRED: "Currency is Required",
  PHONE_NO_REQUIRED: "Phone Number is Required",
  FILE_SIZE_ERROR: "File size must be less then 2 MB",
  LOGO_SIZE_ERROR: "Logo size must be less then 1 MB",
  INVALID_FILE: "Only file with extension (.jpg, jpeg, JPG, and png) are allowed.",
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  ADDRESS: 'address',
  DOB: 'dob',
  ROLE_ID: 'roleId',
  COUNTRY_CODE: 'countryCode',
  CURRENCY: 'currency',
  COUNTRY_ID: 'countryId',
  MOBILE: 'mobile',
  PROFILE_IMAGE: 'profileImage',
  NO_INTERNET_CONNECTION: 'Please check your internet connectivity',
  COUNTRY_NAME: 'countryName',
  PABSA_SUPER_ADMIN: 'CS Super Admin',
  PABSA_ADMIN: 'CS Admin',
  CLUB_SUPER_ADMIN: 'Club Super Admin',
  CLUB_ADMIN: 'Club Admin',
  PLAYER: 'Member',
  EDIT_CLUB: 'EDIT_CLUB',
  SHOW_CLUB: 'SHOW_CLUB',
  STATUS_ID: 'statusId',
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
  LIVE: 'Live',
  PASSWORD_NOT_SET: 'Password Not Set',
  EXPIRING_SOON: 'Expiring Soon',
  NOT_STARTED: 'Not Started',
  COMPLETED: 'Completed',
  LIVE: 'Live',
  CLUBS: "clubs",
  VIEW: "VIEW",
  EDIT: "EDIT",
  PABSA_SUPER_ADMIN_VALUE: 1,
  PABSA_ADMIN_VALUE: 2,
  CLUB_SUPER_ADMIN_VALUE: 3,
  CLUB_ADMIN_VALUE: 4,
  PLAYER_VALUE: 5,
  COUNTRY_LIST: "countryList",
  CITY: 'city',
  HEIGHT: 'height',
  highestBilliardsPracticeBreak: 'highestBilliardsPracticeBreak',
  highestBilliardsTournamentBreak: 'highestBilliardsTournamentBreak',
  highestSnookerPracticeBreak: 'highestSnookerPracticeBreak',
  highestSnookerTournamentBreak: 'highestSnookerTournamentBreak',
  STATE: 'state',
  streetAddress1: 'streetAddress1',
  streetAddress2: 'streetAddress2',
  styleOfPlaying: 'styleOfPlaying',
  ZIP: 'zip',
  GENDER: 'gender',
  USERID: 'userId',
  userPin:'userPin',
  maintenancePin:'maintenancePin',
  isPrivacyOn:'isPrivacyOn',
  REDBALLS15: 'REDBALLS15',
  RED_BALLS_15: '15 Red Balls',
  BILLIARDS: 'BILLIARDS',
  SNOOKER: 'SNOOKER',
  POOL: 'POOL',
  CAROM: 'CAROM3CUSHION',
  RUSSIAN: 'RUSSIANPYRAMID',
  BILLIARDS_LOWERCASE: 'Billiards',
  SNOOKER_LOWERCASE: 'Snooker',
  POOL_LOWERCASE: 'Pool',
  CAROM_LOWERCASE: 'Carom 3 cushion',
  RUSSIAN_LOWERCASE: 'Russian Pyramid',
  CLUBID: 'clubid',
  COMPLETED_MATCH_ID: 6,
  UPCOMING_MATCH_ID: 1,
  MANAGE_MATCH: "MANAGE_MATCH",
  MANAGE_TABLE: "MANAGE_TABLE",
  PLAYER_MATCH: "PLAYER_MATCH",
  PASSWORD_NOT_SET_VALUE: 4,
  ROUNDROBIN: 'ROUNDROBIN',
  KNOCKOUT: 'KNOCKOUT',
  ROUNDROBIN_LOWERCASE: 'Round-Robin',
  KNOCKOUT_LOWERCASE: 'Knockout',
  ROUNDROBIN_UPPERCASE: 'ROUND-ROBIN',
  KNOCKOUT_UPPERCASE: 'KNOCKOUT',
  LIVE_STREAM_PARENT: "localhost",
  HOME_REFRESH_SECONDS: 10,
  ALPHA_NUMERIC: /^[a-zA-Z0-9- ]*$/,
  PoolTypes:[{name:"8 Balls",value:"8BALL"},{name:"9 Balls",value:"9BALL"},{name:"10 Balls",value:"10BALL"},{name:"Straight pool",value:"STRAIGHTPOOL"},{name:"One pocket",value:"ONEPOCKET"}],
  BilliardTypes:[{name:"100 Up",value:"100UP"},{name:"150 Up",value:"150UP"},{name:"Timed",value:"TIMED"}],
  SnookerTypes:[{name:" 6 Ball",value:"6BALL"},{name:"15 Red Balls",value:"REDBALLS15"}],
  BALL8_LOWERCASE: "8 Balls",
  BALL9_LOWERCASE: "9 Balls",
  BALL10_LOWERCASE: "10 Balls",
  STRAIGHTPOOL_LOWERCASE : "Straight pool",
  ONEPOCKET_LOWERCASE : "One pocket",
  BALL6_LOWERCASE: "6 Balls",
  BALL15_LOWERCASE : "15 Red Balls",
  UP100_LOWERCASE : "100 Up",
  UP150_LOWERCASE : "150 Up",
  TIMED_LOWERCASE : "Timed",
  BILLIARDS_UPPERCASE: 'BILLIARDS',
  SNOOKER_UPPERCASE: 'SNOOKER',
  POOL_UPPERCASE: 'POOL',
  CAROM_UPPERCASE: 'CAROM 3 CUSHION',
  RUSSIAN_UPPERCASE: 'RUSSIAN PYRAMID',
  EULA_ACCEPT: 'eulaNotAccepted',
  emailRegex:/^[a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+$/,
  Type: {
    IOS: "IOS",
    ANDROID: "ANDROID",
    RN_ANDROID:"RN_ANDROID",
    SMARTTV: "SMARTTV"
},
  day:{
    "00:00 AM": 0,
    "00:30 AM": 1800000,
    "01:00 AM": 3600000,
    "01:30 AM": 5400000,
    "02:00 AM": 7200000,
    "02:30 AM": 9000000,
    "03:00 AM": 10800000,
    "03:30 AM": 12600000,
    "04:00 AM": 14400000,
    "04:30 AM": 16200000,
    "05:00 AM": 18000000,
    "05:30 AM": 19800000,
    "06:00 AM": 21600000,
    "06:30 AM": 23400000,
    "07:00 AM": 25200000,
    "07:30 AM": 27000000,
    "08:00 AM": 28800000,
    "08:30 AM": 30600000,
    "09:00 AM": 32400000,
    "09:30 AM": 34200000,
    "10:00 AM": 36000000,
    "10:30 AM": 37800000,
    "11:00 AM": 39600000,
    "11:30 AM": 41400000,
    "12:00 PM": 43200000,
    "12:30 PM": 45000000,
    "01:00 PM": 46800000,
    "01:30 PM": 48600000,
    "02:00 PM": 50400000,
    "02:30 PM": 52200000,
    "03:00 PM": 54000000,
    "03:30 PM": 55800000,
    "04:00 PM": 57600000,
    "04:30 PM": 59400000,
    "05:00 PM": 61200000,
    "05:30 PM": 63000000,
    "06:00 PM": 64800000,
    "06:30 PM": 66600000,
    "07:00 PM": 68400000,
    "07:30 PM": 70200000,
    "08:00 PM": 72000000,
    "08:30 PM": 73800000,
    "09:00 PM": 75600000,
    "09:30 PM": 77400000,
    "10:00 PM": 79200000,
    "10:30 PM": 81000000,
    "11:00 PM": 82800000,
    "11:30 PM": 84600000
},
year : {
  "2022" : 2022,
  "2023" : 2023,
  "2024" : 2024,
  "2025" : 2025,
  "2026" : 2026,
  "2027" : 2027,
  "2028" : 2028,
  "2029" : 2029,
  "2030" : 2030,
  "2031" : 2031,
  "2032" : 2032,
  "2033" : 2033,
  "2034" : 2034,
  "2035" : 2035,
  "2036" : 2036,
  "2037" : 2037,
  "2038" : 2038,
  "2039" : 2039,
  "2040" : 2040,
},
ranking : {
  "1" : 1,
  "2" : 2,
  "3" : 3,
  "4" : 4,
  "5" : 5,
  "6" : 6,
  "7" : 7,
  "8" : 8,
  "9" : 9,
  "10" : 10,
  "11" : 11,
  "12" : 12,
  "13" : 13,
  "14" : 14,
  "15" : 15,
  "17" : 17,
  "18" : 18,
  "19" : 19,
  "20" : 20,
  "21" : 21,
  "22" : 22,
  "23" : 23,
  "24" : 24,
  "25" : 25,
  "26" : 26,
  "27" : 27,
  "28" : 28,
  "29" : 29,
  "30" : 30,
  "31" : 31,
  "32" : 32,
},
GroupN_32 : ["Z1","Z2","Z3","Z4","Z5","Z6"],
};

export default constant;

export const APP_TYPES = {
  CLUB: 'Cloud Snooker Club App',
  SCORING: 'Cloud Snooker Scoring App',
  STREAMING: 'Cloud Snooker Streaming App',
};

export const PLATFORM_TYPES = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  RN_ANDROID: 'RN_ANDROID',
};
