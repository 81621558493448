let BASE_URL_PATH;
let PUBLISHER_KEY;
let currentYear = new Date().getFullYear();

if (process.env.NODE_ENV === 'development') {
  BASE_URL_PATH =  'https://pabsa.impressicocrm.com/api'//'https://172.20.22.27/api'

  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'

} else if (process.env.REACT_APP_NODE_ENV === 'production') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_live_51HZKJkEJddy2vLneP5j80sHzqxIUtzFQ5sNXlTH0xDUmLnrne75PQcN8r1Qn2Ifk0BPVDERRgYmPx7apIz2ZUD0X00KZ4hdBxB'
} else if (process.env.REACT_APP_NODE_ENV === 'staging') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
} else if (process.env.REACT_APP_NODE_ENV === 'qa') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
} else if (process.env.REACT_APP_NODE_ENV === 'dev') {
  BASE_URL_PATH = process.env.REACT_APP_BASE_URL
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
}
else {
  BASE_URL_PATH = 'https://pabsa.impressicocrm.com/api'
  PUBLISHER_KEY = 'pk_test_51ILmNdJLVloLuuYW8RvPEuauFdAPKhj7Eb3gBeR3aSD44ECLmPV4kLoujy8QMTbn9hmTVl73JgXzBafFoeErOesV003NB5mptu'
}

const config = {
  BASE_URL: BASE_URL_PATH,
  PAGE_SIZE: 10,
  PAGE_SIZE_20: 20,
  STRIPE_PUBLISHER_KEY: PUBLISHER_KEY,
  CURRENT_YEAR: currentYear,
};

export default config;
